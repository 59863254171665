import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import { useLoadCanvas, useLineAndArrow, useDropUnit } from '../../services/hooks/canvasHooks';
import Actions from './Actions';
import Tools from './Tools';
import styles from './styles';
import { TREATMENT_PHOTO_WIDTH } from '../../constants/treatment';
import { useSelector } from 'react-redux';
import { useDragLayer } from 'react-dnd';
import { Preview } from 'react-dnd-preview';
import UnitButtonLayer from '../customer/treatment/TreatmentDesignForm/UnitButton/UnitButtonLayer';

function Canvas({ classes, formRef, updateForm, canvas, photo, canvasSizeProps, dragSnapShot }) {
    const canvasRef = useRef(null);
    const [drawerStyles, setDrawerStyles] = useState({});
    const isDrawerLocked = useSelector(({ treatment }) => {
        const canvasMetadata = treatment?.canvasMetadata || {};
        return !['inArrowMode', 'inBrushMode', 'inLineMode', 'inShapeMode'].some(key => canvasMetadata[key]);
    });
    const { isDragging } = useDragLayer(monitor => {
        return {
            isDragging: monitor.isDragging()
        };
    });

    useEffect(() => {
        if (drawerStyles.height && canvas.current) {
            canvas.current.setHeight(drawerStyles.height);
        }
    });

    useEffect(() => {
        if (photo && photo.url !== 'None') {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = photo.url;
            img.onload = loadedImg => {
                const width = TREATMENT_PHOTO_WIDTH;
                const height = (() => {
                    if (img) {
                        const scale = width / img.width;
                        return img.naturalHeight * scale;
                    } else if (loadedImg.path && loadedImg.path[0]) {
                        const scale = width / loadedImg.path[0].width;
                        return loadedImg.path[0].height * scale;
                    }
                })();
                canvas.current.setDimensions({
                    height,
                    width
                });
                canvasSizeProps.current = { height, width };
                setDrawerStyles({
                    height,
                    width,
                    backgroundImage: `url(${photo.url})`,
                    backgroundSize: '100% 100%'
                });
            };
        } else {
            setDrawerStyles({
                height: drawerStyles.height,
                width: drawerStyles.width
            });
        }
        //eslint-disable-next-line
    }, [photo]);

    const dropZone = useDropUnit(canvas, canvasRef, formRef, updateForm, dragSnapShot);

    useLoadCanvas(canvas, canvasRef, formRef, updateForm);
    useLineAndArrow(canvas, formRef);

    const UnitPreview = ({ item, style }) => {
        return (
            <div style={{ ...style, zIndex: 10000 }}>
                <UnitButtonLayer item={item} style={style} dragSnapShot={dragSnapShot} />
            </div>
        );
    };

    UnitPreview.propTypes = {
        item: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired
    };

    return (
        <div className={classes.treatmentBlock} id="treatment-canvas" ref={dropZone}>
            <div
                style={{
                    ...drawerStyles,
                    pointerEvents: formRef.current.lockEdit || (isDrawerLocked && !isDragging) ? 'none' : 'auto'
                }}
            >
                <canvas
                    id="fabric-canvas"
                    height={drawerStyles.height?.toString() || '300'}
                    width={TREATMENT_PHOTO_WIDTH}
                    ref={canvasRef}
                ></canvas>
                &nbsp; {/* strange tweak to make apple pen working when drawing lines slow - keep nbsp here!!! */}
            </div>
            <Box my={2} display="flex" justifyContent="space-between" flexDirection="row">
                <Tools canvas={canvas} formRef={formRef} />
                <Actions canvas={canvas} updateForm={updateForm} formRef={formRef} />
            </Box>
            <Preview generator={UnitPreview} />
        </div>
    );
}

Canvas.propTypes = {
    classes: PropTypes.object.isRequired,
    formRef: PropTypes.object.isRequired,
    updateForm: PropTypes.func.isRequired,
    canvas: PropTypes.object.isRequired,
    photo: PropTypes.object,
    canvasSizeProps: PropTypes.object.isRequired,
    dragSnapShot: PropTypes.object.isRequired
};

export default withStyles(styles)(Canvas);
