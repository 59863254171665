import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';

import { calculateFinalPrice } from '../../../collums-constants/utils';
import { COURSES_GROUP } from '../../../collums-constants';

import { toLocaleString } from '../../../collums-components/helpers/index';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';

import { getFormattedDate } from '../../../services/helpers';

import { loadInvoice } from '../../../actions/invoiceActions';
import { loadCourseHistoryModal } from '../../../actions/courseActions';

import {
    AppBar,
    Card,
    Button,
    Menu,
    MenuItem,
    Fade,
    Box,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TableSortLabel,
    TablePagination
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import StyledTableRow from '../../common/StyledTableRow';
import NotFound from '../../common/NotFound';

import VoidModal from './modals/VoidCourse';
import ExpDateModal from './modals/ExpiryDate';
import UnredeemModal from './modals/UnredeemCourse';
import RedeemModal from './modals/RedeemCourse';
import CourseHistoryModal from './modals/CourseHistoryModal';

import CustomerApi from '../../../api/customerApi';
import coursesApi from '../../../api/courseApi';

import styles from '../styles';

import tableColumns from './tableColumns';

const initialSortState = {
    sortDirection: 'desc',
    orderBy: 'Exp date',
    id: 'data.endsOn'
};

function CustomerCourses({ customer, classes }) {
    const [group, setGroup] = useState(COURSES_GROUP.all);
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState(initialSortState);
    const [active, setActive] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [expired, setExpired] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpDateModalOpen, setIsExpDateModalOpen] = useState(false);
    const [isVoidConfirmationModalOpen, setIsVoidConfirmationModalOpen] = useState(false);
    const [isUnredeemModalOpen, setIsUnredeemModalOpen] = useState(false);
    const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);

    async function getMetaData() {
        const metaData = await CustomerApi.getCustomerCoursesMetaData(customer.id);
        setCount(metaData.total);
        setActive(metaData.active);
        setCompleted(metaData.completed);
        setExpired(metaData.expired);
        setTotal(metaData.total);
    }

    async function getCourses(orderId = order.id, sortDirection = order.sortDirection, grp = group) {
        if (customer) {
            try {
                setIsLoading(true);
                const queryString = `?order=${
                    sortDirection === 'asc' ? '' : '-'
                }${orderId}&page=${page}&rowsPerPage=${rowsPerPage}&group=${grp}`;
                const courses = await CustomerApi.getCustomerCourses(customer.id, queryString);
                setCourses(courses.courses || []);
                setIsLoading(false);
            } catch (e) {
                console.error(e);
            }
        }
    }

    const refreshPage = async () => {
        await getMetaData();
        await getCourses();
    };

    const changeGroup = async newGrp => {
        if (group === newGrp) return;
        const total = () => {
            if (newGrp === COURSES_GROUP.active) return active;
            if (newGrp === COURSES_GROUP.completed) return completed;
            if (newGrp === COURSES_GROUP.expired) return expired;
            return count;
        };
        setTotal(total);
        if (page === 0) getCourses(undefined, initialSortState, newGrp);
        else setPage(0);
    };

    useEffect(() => {
        refreshPage();
        /* eslint-disable-next-line */
    }, [customer, page, rowsPerPage]);

    const handleTabChange = async value => {
        history.push(`/customer/${customer.id}/courses/${value}`);
        setGroup(value);
    };

    const openCourseHistory = courseId => {
        dispatch(loadCourseHistoryModal(courseId));
    };

    const openInvoice = invoice => {
        dispatch(loadInvoice(invoice.id));
    };

    const handleOpenMenu = event => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);

    const handleSort = async column => {
        if (column.name === order.orderBy) {
            const sortDirection = order.sortDirection === 'asc' ? 'desc' : 'asc';
            setOrder({
                ...order,
                sortDirection,
                id: column.id
            });
            await getCourses(column.id, sortDirection);
        } else {
            setOrder({
                orderBy: column.name,
                sortDirection: 'asc',
                id: column.id
            });
            await getCourses(column.id, 'asc');
        }
    };

    const handleCloseExpDateModal = () => {
        setIsExpDateModalOpen(false);
        setSelectedCourseId(null);
        setSelectedServiceId(null);
        setExpiryDate(null);
    };
    const handleCloseVoidConfirmationModal = () => {
        setIsVoidConfirmationModalOpen(false);
        setSelectedCourseId(null);
        setSelectedServiceId(null);
    };
    const handleCloseUnredeemModal = () => {
        setIsUnredeemModalOpen(false);
        setSelectedCourseId(null);
        setSelectedServiceId(null);
    };
    const handleCloseRedeemModal = () => {
        setIsRedeemModalOpen(false);
        setSelectedCourseId(null);
        setSelectedServiceId(null);
    };

    const onVoidModalConfirm = async () => {
        try {
            await coursesApi.voidCustomerCourse(selectedCourseId, selectedServiceId);
            await refreshPage();
            handleCloseVoidConfirmationModal();
            toastr.success('Course successfully voided');
        } catch (e) {
            console.error(e);
            if (e?.data?.message) {
                toastr.error(e.data.message);
                return;
            }
            toastr.error('Something went wrong (code: c0027)');
        }
    };
    const onUnredeemModalConfirm = async () => {
        try {
            await coursesApi.unredeemLastService(selectedCourseId, selectedServiceId);
            await refreshPage();
            handleCloseUnredeemModal();
            toastr.success('Last service successfully unredeemed');
        } catch (e) {
            console.error(e);
            if (e?.data?.message) {
                toastr.error(e.data.message);
                return;
            }
            toastr.error('Something went wrong (code: c0028)');
        }
    };
    const onRedeemModalConfirm = async () => {
        try {
            await coursesApi.redeemCourse(selectedCourseId, selectedServiceId);
            await refreshPage();
            handleCloseRedeemModal();
            toastr.success('Course successfully redeemed');
        } catch (e) {
            console.error(e);
            if (e?.data?.message) {
                toastr.error(e.data.message);
                return;
            }
            toastr.error('Something went wrong (code: c0029)');
        }
    };
    const onExpDateModalSave = async newDate => {
        try {
            await coursesApi.updateExpiryDate(selectedCourseId, { newEndsOn: newDate });
            await refreshPage();
            handleCloseExpDateModal();
            toastr.success('Expiry date was successfully updated');
        } catch (e) {
            console.error(e);
            if (e?.data?.message) {
                toastr.error(e.data.message);
                return;
            }
            toastr.error('Something went wrong (code: c0030)');
        }
    };

    return (
        <div>
            {isLoading && <LoadingScreen />}
            <AppBar className={classes.whiteBar} classes={{ root: classes.noShadow }} position="static">
                <Tabs
                    classes={{ indicator: classes.tabIndicator }}
                    centered
                    variant="scrollable"
                    value={group || 'all'}
                    onChange={(e, value) => {
                        changeGroup(value);
                        handleTabChange(value);
                    }}
                >
                    <Tab
                        className={classes.tab}
                        disabled={false}
                        value={COURSES_GROUP.all}
                        label={`${COURSES_GROUP.all} (${count})`}
                    />
                    <Tab
                        className={classes.tab}
                        value={COURSES_GROUP.active}
                        disabled={!active}
                        label={`${COURSES_GROUP.active} (${active})`}
                    />
                    <Tab
                        className={classes.tab}
                        value={COURSES_GROUP.completed}
                        disabled={!completed}
                        label={`${COURSES_GROUP.completed} (${completed})`}
                    />
                    <Tab
                        className={classes.tab}
                        value={COURSES_GROUP.expired}
                        disabled={!expired}
                        label={`${COURSES_GROUP.expired} (${expired})`}
                    />
                </Tabs>
            </AppBar>
            <Card style={{ background: '#f7f7f7' }}>
                {courses.length > 0 ? (
                    <>
                        <Box className={classes.electronicNotesTableGrid}>
                            <Table size="medium" stickyHeader style={{ padding: 20 }}>
                                <TableHead>
                                    <TableRow>
                                        {tableColumns.map(column => (
                                            <TableCell
                                                sortDirection={
                                                    column.name === order.orderBy ? order.sortDirection : false
                                                }
                                                align={column.numeric ? 'right' : 'left'}
                                                key={column.name}
                                                className={classes.tableHeader}
                                            >
                                                {column.reordable ? (
                                                    <TableSortLabel
                                                        active={column.name === order.orderBy}
                                                        direction={order.sortDirection}
                                                        onClick={() => handleSort(column)}
                                                    >
                                                        {column.name}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.name
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {courses.map(current => {
                                        return current.data.map((curr, currIndex) => {
                                            let serviceDisplay = '';
                                            if (curr.additionalServicesOryg && curr.additionalServicesOryg.length) {
                                                if (curr.additionalServices.serviceDetails[0]) {
                                                    serviceDisplay = curr.additionalServices.serviceDetails[0].name;
                                                } else if (curr.serviceDetailsMain[0]) {
                                                    serviceDisplay = curr.serviceDetailsMain[0].name;
                                                }
                                            }
                                            return (
                                                <StyledTableRow
                                                    className={classes.tableRow}
                                                    key={`${curr.id}:${currIndex}/${current.length}`}
                                                    hover
                                                    onClick={() => openCourseHistory(curr)}
                                                >
                                                    {/* Name */}
                                                    <TableCell className={classes.tableBodyCellLarge}>
                                                        <b>{curr.course.name}</b>
                                                        {serviceDisplay && (
                                                            <>
                                                                <br />
                                                                {serviceDisplay} x{curr.quantity}
                                                            </>
                                                        )}
                                                    </TableCell>
                                                    {/* Invoice */}
                                                    <TableCell className={classes.tableBodyCellLarge}>
                                                        {curr.invoice ? (
                                                            <Button
                                                                className={classes.invoiceButton}
                                                                key={current.invoice?.code || current.id}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    openInvoice(current.invoice);
                                                                }}
                                                            >
                                                                {current.invoice?.originalCode || current.invoice?.code}
                                                            </Button>
                                                        ) : (
                                                            <p>Invoice not found</p>
                                                        )}
                                                    </TableCell>
                                                    {/* Amount Paid */}
                                                    <TableCell
                                                        className={classes.tableBodyCellLarge}
                                                        style={{ textAlign: 'end' }}
                                                    >
                                                        {curr.invoiceItem
                                                            ? curr.grossAmount !== undefined
                                                                ? toLocaleString(curr.grossAmount)
                                                                : toLocaleString(
                                                                      calculateFinalPrice(curr.invoiceItem) *
                                                                          curr.amountPercentage
                                                                  )
                                                            : toLocaleString(0)}
                                                    </TableCell>
                                                    {/* Service Value */}
                                                    <TableCell
                                                        className={classes.tableBodyCellLarge}
                                                        style={{ textAlign: 'end' }}
                                                    >
                                                        {curr.invoiceItem &&
                                                            (curr.grossAmount !== undefined
                                                                ? toLocaleString(curr.grossAmount / curr.quantity)
                                                                : toLocaleString(
                                                                      (calculateFinalPrice(curr.invoiceItem) *
                                                                          curr.amountPercentage) /
                                                                          curr.quantity
                                                                  ))}
                                                    </TableCell>
                                                    {/* Exp date */}
                                                    <TableCell className={classes.tableBodyCellLarge}>
                                                        {getFormattedDate(new Date(curr.endsOn))}
                                                    </TableCell>
                                                    {/* Services */}
                                                    <TableCell
                                                        className={classes.tableBodyCellLarge}
                                                        style={{ textAlign: 'end' }}
                                                    >
                                                        {curr.quantity || curr.course.quantity || 1}
                                                    </TableCell>
                                                    {/* Used */}
                                                    <TableCell
                                                        className={classes.tableBodyCellLarge}
                                                        style={{ textAlign: 'end' }}
                                                    >
                                                        {curr.redeemed}
                                                    </TableCell>
                                                    {/* Remaining */}
                                                    <TableCell
                                                        className={classes.tableBodyCellLarge}
                                                        style={{ textAlign: 'end' }}
                                                    >
                                                        {curr.isCompleted
                                                            ? 0
                                                            : (curr.quantity || curr.course.quantity || 1) -
                                                              curr.redeemed}
                                                    </TableCell>
                                                    <TableCell className={classes.tableBodyCellLarge}>
                                                        {curr.location?.accountName || ''}
                                                    </TableCell>
                                                    <TableCell className={classes.tableBodyCellLarge}>
                                                        <Button
                                                            className={classes.optionsButton}
                                                            variant="outlined"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setSelectedCourseId(curr.id);
                                                                if (curr.additionalServices.serviceDetails[0]) {
                                                                    setSelectedServiceId(
                                                                        curr.additionalServices.serviceDetails[0].id
                                                                    );
                                                                } else {
                                                                    setSelectedServiceId(curr.serviceDetailsMain[0].id);
                                                                }

                                                                setExpiryDate(curr.endsOn);
                                                                handleOpenMenu(e);
                                                            }}
                                                        >
                                                            Options
                                                        </Button>
                                                        <Menu
                                                            id={`options-${currIndex}`}
                                                            anchorEl={anchorEl}
                                                            open={!!anchorEl}
                                                            TransitionComponent={Fade}
                                                            className={classes.optionsMenu}
                                                            onClose={handleCloseMenu}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setIsExpDateModalOpen(true);
                                                                    handleCloseMenu();
                                                                }}
                                                            >
                                                                Edit exp date
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setIsVoidConfirmationModalOpen(true);
                                                                    handleCloseMenu();
                                                                }}
                                                            >
                                                                Void course
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setIsUnredeemModalOpen(true);
                                                                    handleCloseMenu();
                                                                }}
                                                            >
                                                                Unredeem last service
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setIsRedeemModalOpen(true);
                                                                    handleCloseMenu();
                                                                }}
                                                            >
                                                                Redeem service
                                                            </MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        });
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(e, page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={e => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                            classes={{ root: classes.tablePagination }}
                        />
                    </>
                ) : (
                    <NotFound />
                )}
            </Card>
            <CourseHistoryModal />
            {isVoidConfirmationModalOpen && (
                <VoidModal onClose={handleCloseVoidConfirmationModal} onConfirm={onVoidModalConfirm} />
            )}
            {isUnredeemModalOpen && (
                <UnredeemModal onClose={handleCloseUnredeemModal} onConfirm={onUnredeemModalConfirm} />
            )}
            {isExpDateModalOpen && (
                <ExpDateModal expiryDate={expiryDate} onClose={handleCloseExpDateModal} onSave={onExpDateModalSave} />
            )}
            {isRedeemModalOpen && <RedeemModal onClose={handleCloseRedeemModal} onConfirm={onRedeemModalConfirm} />}
        </div>
    );
}

CustomerCourses.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object
};

export default withStyles(styles)(CustomerCourses);
