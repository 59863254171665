import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { styles } from './styles';
import InsuranceDetailsModal from './InsuranceDetailsModal';
import InsuranceApi from '../../../api/insuranceApi';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';

const InsuranceDetails = ({ customer, classes }) => {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [insurance, setInsurance] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const setInsuranceDetails = useCallback(async () => {
        setIsLoading(true);

        try {
            const insurance = await InsuranceApi.getForCustomer(customer.id);
            if (insurance?.length) {
                setInsurance(insurance[0]);
            }
        } catch (e) {
            toastr.error('Could not load customer insurance data');
        } finally {
            setIsLoading(false);
        }
    }, [customer.id]);

    useEffect(() => {
        setInsuranceDetails();
    }, [customer.id, setInsuranceDetails]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Grid container className={classes.infoCard} spacing={1}>
                <Grid item container direction="row">
                    <Grid item xs={12} justifyContent="center" alignContent="center">
                        <Typography align="center" variant="h4" className={classes.bold}>
                            Insurance Details{' '}
                            <Edit
                                onClick={() => {
                                    setIsFormModalOpen(true);
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Insurance Company:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{insurance?.company || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Membership No:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{insurance?.number || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Authorisation No:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{insurance?.authNumber || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Phone
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{insurance?.phone || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Other
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{insurance?.other || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <InsuranceDetailsModal
                open={isFormModalOpen}
                insurance={insurance}
                customer={customer}
                afterSave={setInsuranceDetails}
                onClose={() => {
                    setIsFormModalOpen(false);
                }}
            />
        </>
    );
};

InsuranceDetails.propTypes = {
    customer: PropTypes.object.isRequired,
    classes: PropTypes.array
};

export default withStyles(styles)(InsuranceDetails);
